<template>
    <div class="apply-comment">
        <div class="title-bar">
            <div class="title">评论</div>
            <span
                class="btn"
                @click="handlerAddComment"
                >添加评论</span
            >
        </div>
        <div class="comment-list" v-if="commentList.length>0">
            <div
                class="comment-item"
                v-for="(item, index) in commentList"
                :key="'comment-' + index"
            >
                <div class="user">
                    <div class="left">
                        <img
                            class="avatar"
                            :src="item.userHeadImg || defaultAvatar"
                            alt=""
                        />
                        <div class="other">
                            <div class="name">{{ item.userName }}</div>
                            <div class="time">{{ item.createTime }}</div>
                        </div>
                    </div>
                    <div class="right">
                        <el-button
                            v-if="item.userId == userId && item.isRevoke == 0"
                            class="btn-revoke"
                            type="text"
                            @click="handleRevokeComment(item.id)"
                        >
                            撤回
                        </el-button>
                    </div>
                </div>
                <div class="content">
                    {{ item.content }}
                </div>
                <div class="file-list">
                    <div
                        class="file-item"
                        v-for="(img, imgIndex) in item.imgList"
                        :key="'img-' + imgIndex + index"
                    >
                        <div class="file-box">
                            <div class="file-type">
                                <el-image
                                    class="img"
                                    :src="img.url"
                                    :preview-src-list="[img.url]"
                                    fit="cover"
                                >
                                </el-image>
                            </div>
                            <div class="file-info">
                                <div class="file-name">
                                    <span class="name" :title="img.name">{{ img.name.split('.').slice(0,-1).join('.') }}.</span>
                                    {{ img.name.split('.').slice(-1)[0]}}
                                </div>
                                <div class="file-size">{{ img.size | formatFileSize }}</div>
                            </div>
                        </div>
                        <el-button
                            type="text"
                            class="download"
                            @click="handleDownload(img)"
                            >下载</el-button
                        >
                    </div>
                </div>
                <div class="file-list">
                    <div
                        class="file-item"
                        v-for="(file, fileIndex) in item.fileList"
                        :key="'file-' + fileIndex + index"
                    >
                        <div class="file-box">
                            <div class="file-type" v-if="imgAccept.indexOf(file.name.split('.').slice(-1)[0]) > -1">
                                <el-image
                                    class="img"
                                    :src="file.url"
                                    :preview-src-list="[file.url]"
                                    fit="cover"
                                >
                                </el-image>
                            </div>
                            <div class="file-type" v-else>
                                <el-image
                                    class="img"
                                    :src="fileTypeImg(file.name)"
                                    fit="cover"
                                ></el-image>
                                <span class="type">{{ file.name.split('.').slice(-1)[0]}}</span>
                            </div>
                            <div class="file-info">
                                <div class="file-name">
                                    <span class="name" :title="file.name">{{ file.name.split('.').slice(0,-1).join('.') }}.</span>
                                    {{ file.name.split('.').slice(-1)[0] }}
                                </div>
                                <div class="file-size">{{ file.size | formatFileSize }}</div>
                            </div>
                        </div>
                        <el-button
                            type="text"
                            class="download"
                            @click="handleDownload(file)"
                            >下载</el-button
                        >
                    </div>
                </div>
                <div class="notice-flag" v-if="item.adviceStatus == '1'">
                    <img src="@/assets/images/campusOA/checked-icon.png" alt="">
                    已通知班主任
                </div>
            </div>
        </div>
        <div class="comment-empty" v-else>
            <img src="@/assets/images/campusOA/empty.png" alt="">
            <p>暂无评论</p>
        </div>
        <!-- 添加评论弹窗 -->
        <dialog-wrapper
            :dialogObj="commentDialogObj"
            :customClass="commentDialogObj.customClass"
            @handleClose="handleCommentCancel"
        >
            <div class="comment-box">
                <el-input
                    v-model="commentTxt"
                    type="textarea"
                    rows="5"
                    placeholder="请填写评论"
                    maxlength="300"
                    show-word-limit
                    class="comment-input"
                />
                <div class="recommend-list">
                    推荐回复
                    <span
                        class="recommend-item"
                        v-for="(item, index) in recommendList"
                        :key="index"
                        @click="handleRecommend(item)"
                    >
                        {{ item }}
                    </span>
                </div>
            </div>
            <div class="upload-file-box img-box">
                <div class="title">
                    <div>图片<span>(最多10张，每张不超过10M)</span></div>
                </div>
                <div class="upload-file-list img-list">
                    <div 
                        class="comment-img" 
                        v-for="(item, index) in commentImgList" 
                        :key="'commentImg-'+index" 
                    >
                        <el-image 
                            :src="item.url" 
                            :preview-src-list="[item.url]"
                            fit="cover" 
                        ></el-image>
                        <i class="el-icon-error del-img" @click="handleRemove('img',index)"></i>
                    </div>
                    
                    <el-upload
                        v-if="commentImgList.length < 10"
                        size="medium"
                        ref="imgUpload"
                        class="comment-img-upload"
                        :action="action"
                        :headers="headers"
                        :show-file-list="false"
                        :accept="imgAccept"
                        :with-credentials="true"
                        :before-upload="(file) => beforeUpload('img', file)"
                        :on-success="(res, file, fileList) => uploadSuccess('img', res, file, fileList)"
                        
                    >
                        <div class="img-upload-btn" slot="default" >
                            <i class="el-icon-plus"></i>
                        </div>
                    </el-upload>
                </div>
            </div>
            <div class="upload-file-box">
                <div class="title">
                    <div>文件<span>(最多5个，每个不超过50M)</span></div>
                    
                    <el-upload
                        v-if="commentFileList.length < 5"
                        ref="fileUpload"
                        :action="action"
                        :headers="headers"
                        :show-file-list="false"
                        :accept="fileAccept"
                        :with-credentials="true"
                        :before-upload="(file) => beforeUpload('file', file)"
                        :on-success="(res, file, fileList) => uploadSuccess('file', res, file, fileList)"
                    >
                            <span  class="upload-btn">
                                添加附件
                            </span>
                    </el-upload>
                </div>
                <div class="upload-file-list">
                    <div
                        class="upload-file-item"
                        v-for="(file, index) in commentFileList"
                        :key="index"
                    >
                        <div class="upload-file-info">
                            <div class="upload-file-type" v-if="imgAccept.indexOf(file.name.split('.').slice(-1)[0]) > -1">
                                <el-image
                                    class="img"
                                    :src="file.url"
                                    :preview-src-list="[file.url]"
                                    fit="cover"
                                >
                                </el-image>
                            </div>
                            <div class="upload-file-type" v-else>
                                <el-image
                                    class="img"
                                    :src="fileTypeImg(file.name)"
                                    fit="cover"
                                ></el-image>
                                <span class="type">{{ file.name.split('.').slice(-1)[0]}}</span>
                            </div>
                            <div class="upload-file-name">
                                <div class="file-name">
                                    <span class="name">{{ file.name.split('.').slice(0,-1).join('.') }}.</span>
                                    {{ file.name.split('.').slice(-1)[0] }}
                                </div>
                                <div class="file-size">{{ file.size | formatFileSize }}</div>
                            </div>
                        </div>
                        <el-button
                            type="text"
                            class="del-file-btn"
                            @click="handleRemove('file', index)"
                            >删除</el-button
                        >
                    </div>
                </div>
            </div>
            <div class="btn-box">
                <el-button
                    type="enquiry"
                    @click="handleCommentCancel"
                    >取消</el-button
                >
                <el-button
                    type="primary"
                    :loading="saveLoading"
                    @click="handleCommentConfirm"
                    >确定</el-button
                >
            </div>
        </dialog-wrapper>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { DialogWrapper, debounce } from "common-local";
import { CampusOAModel } from "@/models/CampusOA";
import { uploadAction } from "@/libs/settings";
import { getToken } from "@/libs/auth";

export default {
    name: "applyComment",
    components: {
        DialogWrapper,
    },
    props: {
        id: {
            type: String,
        },
    },
    data() {
        return {
            campusOA: null,
            commentList: [],
            commentDialogObj: {
                title: "添加评论",
                customClass: "comment-dialog",
                dialogVisible: false,
                width: "690px",
            },
            commentTxt: "", // 评论内容
            recommendList: [
                "同意",
                "确认",
                "OK",
                "好的",
                "通过",
                "已审核",
                "已核实",
            ], // 推荐回复
            imgAccept: '.jpeg, .png, .jpg', // 图片上传格式
            fileAccept: '.jpg,.jpeg,.png,.gif,.bmp,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.pdf,.txt,.zip,.rar,.7z,.JPG,.JPEG,.PNG,.GIF,.BMP,.DOC,.DOCX,.PPT,.PPTX,.XLS,.XLSX,.PDF,.TXT,.ZIP,.RAR,.7Z', // 文件上传格式
            commentImgList: [], // 评论的图片列表
            commentFileList: [], // 评论的文件列表
            saveLoading: false, // 保存loading
        };
    },
    computed: {
        ...mapState({
            userId: (state) => state.userId,
            schoolId: (state) => state.schoolId,
        }),
        action() {
            return uploadAction
        },
        headers() {
            return {
                Authorization: getToken(),
            }
        },
        defaultAvatar() {
            return require("@/assets/images/avatar.png");
        },
        imgAttachmentImg() {
            return require("@/assets/images/campusOA/attachment-img.png");
        },
        imgAttachmentWord() {
            return require("@/assets/images/campusOA/attachment-word.png");
        },
        imgAttachmentTxt() {
            return require("@/assets/images/campusOA/attachment-txt.png");
        },
        imgAttachmentZip() {
            return require("@/assets/images/campusOA/attachment-zip.png");
        },
    },
    filters: {
        formatFileSize(size) {
            if (!size) {
                return "";
            } else if (size < 1024) {
                return size + "kb";
            } else if (size < 1024 * 1024) {
                return (size / 1024).toFixed(2) + "M";
            } else if (size < 1024 * 1024 * 1024) {
                return (size / (1024 * 1024)).toFixed(2) + "G";
            }
        }
    },
    created() {
        this.campusOA = new CampusOAModel();
        this.getCommentList();
    },
    mounted() {
        console.log("formInfo---------", this.formInfo);
    },
    methods: {
        /**
         * @Description: 获取评论列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王妙田
         * @Date: 2025-01-14 11:24:17
         */
        getCommentList() {
            console.log("getCommentList");
            this.campusOA.getCommentList({applyId: this.id, schoolId: this.schoolId}).then((res) => {
                console.log("getCommentList", res);
                this.commentList = res.data.data;
            });
        },
        /**
         * @Description: 附件图标
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王妙田
         * @Date: 2025-01-14 10:23:04
         * @param {*} name
         */
        fileTypeImg(name) {
            const type = name.split(".").slice(-1)[0];
            const fileType = type ? type.toLowerCase() : "";
            if (
                fileType === "jpg" || 
                fileType === "png" || 
                fileType === "jpeg" || 
                fileType === "gif" || 
                fileType === "bmp"
            ) {
                return this.imgAttachmentImg;
            } else if (
                fileType === "doc" || 
                fileType === "docx" || 
                fileType === "ppt" || 
                fileType === "pptx" ||
                fileType === "xls" || 
                fileType === "xlsx" ||
                fileType === "pdf"
            ) {
                return this.imgAttachmentWord;
            } else if (
                fileType === "zip" || 
                fileType === "rar" || 
                fileType === "7z"
            ) {
                return this.imgAttachmentZip;
            } else if (fileType === "txt") {
                return this.imgAttachmentTxt;
            }
        },
        handleDownload(fileInfo) {
            console.log("[handleDownload]fileInfo:", fileInfo);
            this.$message({
                message: "正在下载···",
                type: "success",
                duration: 2000,
            });
            this.downloadFile(fileInfo.url, fileInfo.name, fileInfo);
        },
        getBlob(url, fileInfo) {
            return new Promise((resolve) => {
                const xhr = new XMLHttpRequest();
                xhr.open("GET", url, true);
                xhr.responseType = "blob";
                xhr.onprogress = (e) => {
                    if (e.loaded < e.total) {
                        this.$set(fileInfo, "downloading", true);
                    } else {
                        this.$set(fileInfo, "downloading", false);
                    }
                };
                xhr.onload = () => {
                    if (xhr.status === 200) {
                        resolve(xhr.response);
                    }
                };
                xhr.send();
            });
        },
        saveAs(blob, filename) {
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        },
        downloadFile(url, name, fileInfo) {
            this.getBlob(url, fileInfo).then((blob) => {
                this.saveAs(blob, name);
            });
        },
        /**
         * @Description: 添加评论
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王妙田
         * @Date: 2025-01-13 16:49:57
         */
        handlerAddComment() {
            this.saveLoading = false;
            this.commentTxt = "";
            this.commentImgList = [];
            this.commentFileList = [];
            this.commentDialogObj.dialogVisible = true;
        },
        /**
         * @Description: 点击推荐回复
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王妙田
         * @Date: 2025-01-13 16:49:57
         */
        handleRecommend(item) {
            if(this.commentTxt.length<=300-item.length) {
                this.commentTxt = this.commentTxt + item;
            } else {
                this.$message.warning('评论字数不能超过300字');
            }
        },
        /**
         * @Description: 上传文件前的校验
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 王妙田
         * @Date: 2025-01-14 17:59:31
         * @param {*} type
         * @param {*} file
         */        
        beforeUpload(type, file) {
            console.log(type, file);
            if(type === 'img') {
                if(this.commentImgList.length == 10) {
                    this.$message.warning('最多上传10张图片');
                    return false;
                }
                const isImg = this.imgAccept.includes(file.type.split('/')[1])
                const isLt10M = file.size / 1024 / 1024 < 10
                const is50 = file.name.length < 50
                if (!isImg) {
                this.$message.error(`上传文件格式仅支持${this.imgAccept}!`);
                    return false
                }
                if (!is50) {
                    this.$message.error('上传文件名称不能超过50个字符!');
                    return false
                }
                if (!isLt10M) {
                    this.$message.error(`上传文件的大小不能超过10M!`)
                    return false
                }
            } else {
                if(this.commentFileList.length == 5) {
                    this.$message.warning('最多上传5个文件');
                    return false;
                }
                const isFile = this.fileAccept.includes(file.name.split('.').slice(-1)[0])
                const isLt50M = file.size / 1024 / 1024 < 50
                const is50 = file.name.length < 50
                if (!isFile) {
                    this.$message({
                        message: `上传文件格式仅支持${this.fileAccept}!`,
                        type: 'error',
                        customClass: 'custom-error'
                    });
                    return false
                }
                // this.$message.error(`上传文件格式仅支持${this.fileAccept}!`);
                //     return false
                // }
                if (!is50) {
                    this.$message.error('上传文件名称不能超过50个字符!');
                    return false
                }
                if (!isLt50M) {
                    this.$message.error(`上传文件的大小不能超过50M!`)
                    return false
                }
            }
        },
        /**
         * @Description: 上传图片、文件成功
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 王妙田
         * @Date: 2025-01-15 10:27:59
         * @param {*} type
         * @param {*} res
         * @param {*} file
         * @param {*} fileList
         */        
        uploadSuccess(type, res, file, fileList) {
            if(type === 'img') {
                this.commentImgList.push(res.data)
            } else {
                this.commentFileList.push(res.data)
            }
        },
        /**
         * @Description: 删除图片、文件
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 王妙田
         * @Date: 2025-01-15 10:30:27
         * @param {*} type
         * @param {*} index
         */        
        handleRemove(type, index) {
            if(type === 'img') {
                this.commentImgList.splice(index, 1)
            } else {
                this.commentFileList.splice(index, 1)
            }
        },
        /**
         * @Description: 添加评论-取消
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王妙田
         * @Date: 2025-01-13 16:50:25
         */
        handleCommentCancel() {
            this.commentDialogObj.dialogVisible = false;
        },
        /**
         * @Description: 添加评论-确定
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王妙田
         * @Date: 2025-01-13 16:50:25
         */
        handleCommentConfirm() {
            this.saveLoading = true;
            if(!this.commentTxt && !this.commentImgList.length && !this.commentFileList.length) {
                this.saveLoading = false;
                return this.$message.error('请输入评论内容或上传图片、文件!')
            }
            
            this.campusOA.saveComment({
                applyId: this.id,
                content: this.commentTxt,
                imgList: this.commentImgList,
                fileList: this.commentFileList,
            }).then((res) => {
                console.log("saveComment", res);
                this.$message.success('评论成功！');
                this.commentTxt = '';
                this.commentImgList = [];
                this.commentFileList = [];
                this.commentDialogObj.dialogVisible = false;
                setTimeout(() => {
                    this.saveLoading = false;
                },300)
                this.getCommentList();
            }).catch((err) => {
                console.log("saveComment", err);
                this.$message.error(err);
            })
        },
        /**
         * @Description: 撤回评论
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王妙田
         * @Date: 2025-01-14 11:32:50
         * @param {*} id
         */
        handleRevokeComment(id) {
            let _this = this;
            this.$confirm('确认要撤回评论吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                //确认
                _this.campusOA.revokeComment({id}).then((res) => {
                    _this.$message.success('撤回成功！');
                    _this.getCommentList();
                });
            }).catch(() => {
                //取消
            });
        },
    },
};
</script>

<style scoped lang="scss">
.apply-comment {
    .title-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-weight: bold;
            font-size: 16px;
            color: #262626;
        }

        .btn {
            display: inline-block;
            cursor: pointer;
            height: 26px;
            line-height: 26px;
            width: 86px;
            text-align: center;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #3c7fff !important;
            color: #3c7fff;
            font-size: 14px;

            &:hover {
                background: #f7f7f7;
            }
        }
    }

    .comment-list {
        margin-top: 6px;
        font-size: 14px;
        color: #262626;

        .comment-item {
            padding: 24px 15px 10px 15px;
            background: #fafafa;
            margin-bottom: 10px;

            .user {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .left {
                    display: inline-flex;
                    align-items: center;

                    .avatar {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        margin-right: 16px;
                    }

                    .other {
                        .name {
                            font-weight: bold;
                            color: #262626;
                            margin-bottom: 10px;
                        }

                        .time {
                            font-size: 12px;
                            color: #9da2a6;
                        }
                    }
                }

                .right {
                    .btn-revoke {
                        color: #f26161;
                    }
                }
            }

            .content {
                padding: 16px 56px;
            }

            .file-list {
                padding-left: 56px;

                .file-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: #f2f2f2;
                    border-radius: 4px;
                    padding: 12px 0 12px 16px;
                    margin-bottom: 6px;

                    .file-box {
                        display: inline-flex;
                        align-items: center;
                        justify-content: flex-start;

                        .file-type {
                            width: 42px;
                            height: 42px;
                            margin-right: 16px;
                            position: relative;

                            .img {
                                width: 100%;
                                height: 100%;
                            }

                            .type {
                                position: absolute;
                                bottom: 6px;
                                left: 50%;
                                transform: translate(-50%, 0);
                                font-size: 11px;
                                color: #878787;
                                line-height: 1;
                                white-space: nowrap;
                            }
                        }
                        .file-info {
                            .file-name {
                                font-weight: bold;
                                display: inline-flex;
                                align-items: center;

                                .name {
                                    display: inline-block;
                                    max-width: 445px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
                            }

                            .file-size {
                                font-size: 12px;
                                color: #505559;
                            }
                        }
                    }
                }
            }

            .notice-flag {
                color: #3C7FFF;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 10px;
                font-size: 12px;

                img {
                    width: 12px;
                    height: 12px;
                    margin-right: 5px;
                }
            }
        }
    }

    .comment-empty {
        text-align: center;
        padding: 36px;
        font-size: 14px;
        color: #808487;
    }
}
// 添加评论弹窗样式
.el-dialog.comment-dialog {
    .comment-box {
        ::v-deep .el-textarea {
            .el-textarea__inner {
                font-family: Microsoft YaHei;
            }
        }

        .recommend-list {
            font-size: 14px;
            color: #2b2f33;

            .recommend-item {
                display: inline-block;
                margin-left: 8px;
                margin-top: 10px;
                background: #f3f3f3;
                border-radius: 12px;
                font-size: 12px;
                color: #2b2f33;
                padding: 3px 16px;
                border-radius: 12px;
                cursor: pointer;

                &:first-child {
                    margin-left: 32px;
                }

                &:hover {
                    background: #d9e6ff;
                }
            }
        }
    }

    .upload-file-box {
        margin-top: 14px;

        .title {
            font-weight: bold;
            font-size: 14px;
            color: #262626;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                font-weight: normal;
                color: #8C8C8C;
            }

            .upload-btn {
                display: inline-block;
                cursor: pointer;
                height: 26px;
                line-height: 26px;
                width: 86px;
                text-align: center;
                background: #ffffff;
                border-radius: 2px;
                border: 1px solid #3c7fff !important;
                color: #3c7fff;
                font-size: 14px;

                &:hover {
                    background: #f7f7f7;
                }
            }
        }

        .img-upload-btn {
            display: inline-block;
            width: 42px;
            height: 42px;
            line-height: 42px;
            background: #FFFFFF;
            border-radius: 2px;
            border: 1px dashed #E0E0E0;
            text-align: center;

            i {
                color: #E0E0E0;
                
            }
        }

        .upload-file-list {

            &.img-list {
                display: flex;
                flex-wrap: wrap;
            }

            .comment-img-upload {
                display: inline-block;
            }

            .comment-img {
                position: relative;
                display: inline-block;
                width: 44px;
                height: 44px;
                border-radius: 2px;
                margin-right: 8px;
                border: 1px solid #E0E0E0;
                cursor: pointer;
                background: #FAFAFA;

                .el-image {
                    width: 100%;
                    height: 100%;

                }

                .del-img {
                    position: absolute;
                    top: -10px;
                    right: -10px;
                    width: 14px;
                    height: 14px;
                    color: #E11204;
                    cursor: pointer;
                    display: none;
                }

                &:hover {
                    .del-img {
                        display: block;
                    }
                }
            }

            .upload-file-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: #f2f2f2;
                border-radius: 4px;
                padding: 12px 0 12px 16px;
                margin-bottom: 6px;

                .upload-file-info {
                    display: inline-flex;
                    align-items: center;
                    justify-content: flex-start;

                    .upload-file-type{
                        width: 42px;
                        height: 42px;
                        margin-right: 16px;
                        position: relative;

                        .img {
                            width: 100%;
                            height: 100%;
                        }

                        .type {
                            position: absolute;
                            bottom: 6px;
                            left: 50%;
                            transform: translate(-50%, 0);
                            font-size: 11px;
                            color: #878787;
                            line-height: 1;
                            white-space: nowrap;
                        }
                    }

                    .upload-file-name {
                        .file-name {
                            font-weight: bold;
                            display: inline-flex;
                            align-items: center;

                            .name {
                                display: inline-block;
                                max-width: 200px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }

                        .file-size {
                            font-size: 12px;
                            color: #505559;
                        }
                    }
                }

                .del-file-btn {
                    color: #f26161;

                }
            }
        }
    }

    .btn-box {
        text-align: right;
        margin-top: 50px;

        .el-button {
            height: 36px;
        }
    }
}
</style>
<style>
    .custom-error {
        width: 1220px;
    }
</style>
